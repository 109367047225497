import styled, {css} from "styled-components";

export const ChargeToCardModalWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 34px 36px 18px;
    width: 354px;
    box-sizing: border-box;
    flex-direction: column;
    background: linear-gradient(180deg, #161222 0%, #2C2545 100%);
    border-radius: 15px;
`

export const ChargeToCardInput = styled.input<{height?: number, withIcon?: boolean, withBorder?: boolean}>`
    width: 100%;
    height: ${({height}) => height ? height : 35}px;
    background: #29294E;
    border: ${({withBorder}) => withBorder ? '1px solid #4B4B6F' : 'none'};
    outline: none;
    position: relative;
    border-radius: 15px;
    padding-left: 8px;
    
    box-sizing: border-box;
    padding-right: ${({withIcon}) => withIcon ? 46 : 8}px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 18px;
    color: #FFFFFF;
    
    text-align: center;
`

export const ChargeToCardWalletSelector = styled.div<{width?: string, height?: string, isBlueStyle?: boolean}>`
    width: ${({width}) => width || '130px'};
    height: ${({height}) => height || '35px'};
    background: #29294E;
    padding: 2px;
    border: none;
    outline: none;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;

    ${({isBlueStyle}) => isBlueStyle && css`
        border-radius: 15px;
        padding: 4px;
    `}
`

export const ChargeToCardWalletSelectorItem = styled.div<{position: 'left' | 'right', active?: boolean, isBlueStyle?: boolean}>`
    border-radius: ${({position}) => position === 'left' ? '10px 0 0 10px' : '0 10px 10px 0'};
    background: ${({active}) => active ? '#1A1629' : 'transparent'};
    height: 100%;
    width: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${({isBlueStyle, active}) => isBlueStyle && css`
        background: ${active ? '#189BFF' : 'transparent'};
        border-radius: 15px;
    `}
`
export const ChargeToCardSubmit = styled.div<{disabled?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 39px;
    background: ${({disabled}) => disabled ? '#4B4B6F' : '#334F32'};
    border-radius: 10px;
`

export const CopyInputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 46px;
    height: 46px;
    background: #29294E;
    border-radius: 15px;
    border: 1px solid #4B4B6F;
    box-sizing: border-box;
`