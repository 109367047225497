import honeyIcon from "../../shared/images/HoneyIcon.svg"
import styled from "styled-components";
import {FC} from "react";

const HoneyIconWrapper = styled.div<{height?: number, width?: number}>`
    background: url(${honeyIcon}) no-repeat;
    width: ${({width}) => width || 26}px;
    height: ${({height}) => height || 26}px;
    background-size: 100%;
`

export const HoneyIcon: FC<{height?: number, width?: number}> = ({height, width}) => {

    return <HoneyIconWrapper height={height} width={width}/>

}