import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import React, {FC} from "react";
import {ChargeToCardInput, CopyInputContainer} from "../../../shared/modals/ChargeToCardModal/styles";
import {TonIcon} from "../styles";
import {copyText} from "../../../shared/actions/copyText";
import {CopyIcon} from "../../../shared/icons/CopyIcon";
import {useTranslation} from "react-i18next";

export const InputItem: FC<{text?: string, withIcon?: boolean, value?: string, onChange?: (value: string) => void, type?: 'default' | 'copy'}> = ({text, withIcon, value = '', onChange, type = 'default'}) => {

    const { t } = useTranslation()

    if (type === 'copy') return <FlexBox direction="column" align="flex-start" gy={8}>
        <TextBlock fontSize={18} lineHeight={18}>{text}</TextBlock>
        <FlexBox width="100%" gx={14} align="center" style={{position: 'relative'}}>
            <ChargeToCardInput disabled withBorder onChange={(e) => onChange && onChange(e.target.value)} value={value} height={46} type="text" withIcon={withIcon}/>
            <CopyInputContainer onClick={() => copyText(text || '', t('share.copied'))}>
                <CopyIcon/>
            </CopyInputContainer>
        </FlexBox>
    </FlexBox>

    return <FlexBox direction="column" align="flex-start" gy={8}>
        {text && <TextBlock fontSize={18} lineHeight={18}>{text}</TextBlock>}
        <FlexBox width="100%" align="center" style={{position: 'relative'}}>
            <ChargeToCardInput style={{textAlign: 'left'}} withBorder onChange={(e) => onChange && onChange(e.target.value)} value={value} height={46} type="text" withIcon={withIcon}/>
            {withIcon && <TonIcon/>}
        </FlexBox>
    </FlexBox>
}