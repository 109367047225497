import {FC, ReactNode} from "react";
import {SettingTabItemContainer} from "../styles";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {ArrowRightIcon} from "../../../shared/icons/ArrowRightIcon";

export const SettingTabItem: FC<{ text: string, action: () => void, icon: ReactNode}> = ({text, action, icon}) => {

    return <SettingTabItemContainer onClick={action}>
        {icon}
        <FlexBox align="center" width={"100%"}>
            <TextBlock fontSize={20} lineHeight={20}>{text}</TextBlock>
        </FlexBox>
        <FlexBox>
            <ArrowRightIcon width={8} height={15}/>
        </FlexBox>
    </SettingTabItemContainer>
}