import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {API_ENDPOINT} from "../const/endpoint";
import {parseJwt} from "../actions/parseJWTToken";
import {collectRewards} from "./farmSlice";
import {collectTask} from "./tasksSlice";

interface AuthInitial {
    userName: string
    token: string
    link: string
    isLoading: boolean
    error: any
    isAdmin: boolean
    isActive: boolean
    bones: number
    notTokens: number
    memoTag: string
}

const initialState: AuthInitial = {
    userName: '',
    token: '',
    link: '',
    isAdmin: false,
    isActive: false,
    bones: 0,
    notTokens: 0,
    memoTag: '',
    isLoading: false,
    error: null
}

interface LoadUserResponse {
    username: string
    token: string
    link: string
    is_admin: boolean
    isActive: boolean
    bones: number
    not_tokens: number
}


export const loadUser = createAsyncThunk<LoadUserResponse, { onBlock: () => void }>(
    'user/Load',
    async ({ onBlock }, thunkAPI) => {
        try {

            const params = new URLSearchParams(window.location.search);
            let user_id = params.get('user_id');
            let username = params.get('username');
            let hash = params.get('hash');
            console.log('tst45', window.location)

            if (!user_id || !username || !hash) {
                return thunkAPI.rejectWithValue('No user data');
            }

            axios.interceptors.response.use(function (response) {
                // Просто возвращаем ответ, если статус не 423
                return response;
            }, function (error) {
                if (error?.status === 423) {
                    onBlock()
                }
                return Promise.reject(error);
            });

            const response = await axios.post(API_ENDPOINT + 'users/login', {
                id: user_id,
                username,
                hash
            }, { withCredentials: true });

            if (!response.data?.username) {
                return thunkAPI.rejectWithValue('Failed to load user');
            }

            axios.interceptors.request.use(function (config) {
                config.headers.Authorization = 'Bearer ' + response.data?.token || '';

                return config;
            });


            let responseLink: any = {}

            try {
                responseLink = await axios.get(API_ENDPOINT + 'users/generate-telegram-link', {});
            } catch (e) {
            }

            return {
                username: response.data?.username || '',
                isActive: response.data?.isActive || false,
                is_admin: response.data?.is_admin || false,
                bones: response.data?.bones || 0,
                not_tokens: response.data?.ton || 0,
                token: response.data?.token || '',
                link: responseLink?.data || '',
            }
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err?.message || 'Failed to load user')
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authorize(state, action) {
            state.userName = action.payload.userName
            state.isAdmin = action.payload.isAdmin
            state.bones = action.payload.bones
            state.notTokens = action.payload.notTokens
        },
        setHoney(state, action) {
            state.bones = action.payload
        },
        setTon(state, action) {
            state.notTokens = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.userName = action.payload.username
                state.isAdmin = action.payload.is_admin
                state.isActive = action.payload.isActive
                state.bones = action.payload.bones
                state.notTokens = action.payload.not_tokens
                state.token = action.payload.token
                state.link = action.payload.link
                state.memoTag = parseJwt(action.payload.token)?.sub || ''
            })
            .addCase(loadUser.pending, (state) => {
                state.isLoading = true
                state.error = ''
            })
            .addCase(loadUser.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
            .addCase(collectRewards.fulfilled, (state, action) => {
                state.bones = action.payload.rewardsCount + state.bones
            })
            .addCase(collectTask.fulfilled, (state, action) => {
                state.bones = action.payload.bonus + state.bones
            })
    }
})

export const { authorize, setHoney, setTon } = authSlice.actions
export const selectorAuth = (state: any): AuthInitial => state.auth
export default authSlice