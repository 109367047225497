import {NavbarWrapper} from "./styles";
import {NavbarFrontBG} from "./components/NavbarFrontBG";
import {NavbarRightItem} from "./components/NavbarRightItem";
import {NavbarLeftItem} from "./components/NavbarLeftItem";
import {NavbarAccount} from "./components/NavbarAccount";
import {useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth} from "../../shared/slices/authSlice";

export const Navbar = () => {

    const { bones, notTokens, userName } = useAppSelector(selectorAuth)

    return <NavbarWrapper>
        <NavbarFrontBG/>
        <NavbarAccount avatar={"https://img.freepik.com/free-photo/beautiful-kitten-with-colorful-clouds_23-2150752964.jpg"} nickName={userName}/>
        <NavbarRightItem count={bones}/>
        <NavbarLeftItem count={notTokens}/>
    </NavbarWrapper>
}