import {useAppDispatch, useAppSelector} from "../storeHooks";
import {useEffect, useMemo} from "react";
import {selectorAuth} from "../../slices/authSlice";
import {Factory, loadFactory, selectorFarm, UserFactory} from "../../slices/farmSlice";

export const useGetFarm = () => {

    const data = useAppSelector(selectorFarm)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || data.isReady || !auth.token || data.error) return
        dispatch(loadFactory({}))

    }, [data.isLoading, data.isReady, auth.token, data.error])

    return data.allFactories.map(factory => {

        const index = data.userFactories.findIndex(userFactory => userFactory.factory_id === factory.id)
        if (index === -1) return {...factory, user_id: 0, factory_id: factory.id, level_id: 0, profit_per_hour: 0, next_level_cost: factory.cost, icon: 'https://img.freepik.com/free-photo/beautiful-kitten-with-colorful-clouds_23-2150752964.jpg'}
        return {...factory, ...data.userFactories[index], icon: 'https://img.freepik.com/free-photo/beautiful-kitten-with-colorful-clouds_23-2150752964.jpg'}
    }) as unknown as (Factory & UserFactory)[]
}