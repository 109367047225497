import styled from "styled-components";

export const SettingsBackgroundIconSvg = styled.svg`

`

export const SettingPageContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    padding-bottom: 160px;

    ${SettingsBackgroundIconSvg} {
        position: absolute;
        width: 100%;
        height: auto;
    }
`

export const SettingPageHeader = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
`

export const SettingPageSubHeader = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
`
export const SettingPageText = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
`

export const LargeAvatarContainer = styled.div`
    width: 125px;
    height: 125px;
    
    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
`

export const ProfileButton = styled.div<{blue?: boolean, width?: string, disabled?: boolean}>`
    
    border-radius: 17px;
    width: ${({width}) => width ? width : '171px'};
    min-width: ${({width}) => width ? width : '171px'};
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: background-color 0.3s;
    background: ${({blue, disabled}) => disabled ? '#151829' : blue ? 'linear-gradient(180.61deg, #189BFF 88.87%, rgba(146, 112, 191, 0.05) 174.85%)' : 'linear-gradient(180.61deg, #929292 88.87%, rgba(146, 112, 191, 0.05) 174.85%)'};
    user-select: none;
    
    svg {
        margin-top: 6px;
    }
    
`
export const SplitLine = styled.div`
    
    width: 100%;
    background: #D9D9D94D;
    height: 2px;
`

export const SettingTabItemContainer = styled.div`

    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    margin-right: 23px;
`

export const CheckBoxItemContainer = styled.div<{active?: boolean}>`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 8px;
    border: ${({active}) => active ? '2px #189BFF solid' : '2px #FFFFFF solid'};
    background: ${({active}) => active ? '#189BFF' : 'transparent'};
    
    svg {
        display: ${({active}) => active ? 'block' : 'none'};
    }
`