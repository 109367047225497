import {FC} from "react";
import {QuestionBlockContainer} from "../styles";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {QuestionIcon} from "../../../shared/icons/QuestionIcon";
import {useTranslation} from "react-i18next";

export const QuestionBlock: FC = () => {

    const { t } = useTranslation()

    return <QuestionBlockContainer>
        <TextBlock fontSize={17} lineHeight={16} fontWeight={500}>{t('cards.question')}</TextBlock>
        <QuestionIcon/>
    </QuestionBlockContainer>
}