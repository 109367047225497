import authSlice from './authSlice';
import { configureStore } from '@reduxjs/toolkit'
import referralsSlice from "./referralsSlice";
import cardGameSlice from "./cardGameSlice";
import farmSlice from "./farmSlice";
import tasksSlice from "./tasksSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        farm: farmSlice.reducer,
        referrals: referralsSlice.reducer,
        tasks: tasksSlice.reducer,
        cardGame: cardGameSlice.reducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store