import React, {FC, useMemo} from "react";
import {FlexBox} from "../../../shared/components/FlexBox/FlexBox";
import {LightningIcon} from "../../../shared/icons/LightningIcon";
import {TextBlock} from "../../../shared/components/Text/TextBlock";
import {FriendItem} from "./FriendItem";
import {useTranslation} from "react-i18next";
import {ReferralsUser} from "../../../shared/slices/referralsSlice";

export const TopFriendsTable: FC<{referrals: ReferralsUser[]}> = ({ referrals }) => {

    const { t } = useTranslation()

    const formattedReferrals = useMemo(() => referrals.map(referral => ({
        nickName: referral?.referral_username || '',
        notCoin: referral.brought_in_bonus?.tonTokens || 0,
        honey: referral.brought_in_bonus?.bones || 0
    })), [referrals])

    return <FlexBox width="100%" direction="column">
        <FlexBox gx={5} align="center">
            <LightningIcon/>
            <TextBlock fontSize={17} lineHeight={18} fontWeight={900}>{t('referrals.friend_list')}</TextBlock>
        </FlexBox>
        <FlexBox width="100%" justify="space-between" offsetTop={10}>
            <FlexBox offsetLeft={10}>
                <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color='#FFFFFFB2'>{t('referrals.name')}</TextBlock>
            </FlexBox>
            <FlexBox>
                <FlexBox offsetRight={56}>
                    <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color='#FFFFFFB2'>NOT</TextBlock>
                </FlexBox>
                <FlexBox offsetRight={28}>
                    <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color='#FFFFFFB2'>HONEY</TextBlock>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <FlexBox width="100%" direction="column" gy={10} offsetTop={10} offsetBottom={60}>
            {formattedReferrals.map((item, key) => <FriendItem key={key} nickName={item.nickName} notCoin={item.notCoin} honey={item.honey}/>)}
        </FlexBox>
    </FlexBox>
}