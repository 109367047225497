import {DefaultPageWrapper} from "../../components/DefaultPageWrapper/DefaultPageWrapper";
import {FlexBox} from "../../shared/components/FlexBox/FlexBox";
import {TextBlock} from "../../shared/components/Text/TextBlock";
import {useTranslation} from "react-i18next";
import {QuestionIcon} from "../../shared/icons/QuestionIcon";
import {InputItem} from "./components/InputItem";
import {ProfileButton, SplitLine} from "../SettingsPage/styles";
import {useEffect, useMemo, useState} from "react";
import {ChargeToCardModal} from "../../shared/modals/ChargeToCardModal/ChargeToCardModal";
import {RequestModal} from "../../shared/modals/RequestModal/RequestModal";
import {useNavigate} from "react-router-dom";
import {createDeposit} from "../../shared/actions/api/createDeposit";
import {useAppSelector} from "../../shared/hooks/storeHooks";
import {selectorAuth} from "../../shared/slices/authSlice";
import {selectorCardGame} from "../../shared/slices/cardGameSlice";
import {WEB_APP_API} from "../../shared/const/WebAppApi";
import {TON_DEPOSIT_WALLET} from "../../shared/const/TonDepositWallet";
import {toast} from "react-toastify";

export const DepositPage = () => {

    const { t } = useTranslation()
    const [stage, setStage] = useState<'form' | 'submit'>('form')
    const [address, setAddress] = useState('')
    const [sum, setSum] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [walletAddress, setWalletAddress] = useState('stage')

    const {memoTag} = useAppSelector(selectorAuth)

    const sumNumber = parseFloat(sum) || 0

    const isButtonDisabled = useMemo(() => Boolean(!sumNumber || !address || isLoading), [sumNumber, address, isLoading])

    const onSubmit = () => {
        if (isButtonDisabled) return;
        if (stage === 'form') {
            setStage('submit')
            return;
        }
        setIsLoading(true)
        createDeposit(address, sumNumber).then((r) => {
            setIsLoading(false)
            setIsModalOpen(true)
        })
            .catch((err) => toast(t('deposit.error')))
    }

    const onCloseModal = () => {
        setIsModalOpen(false)
        navigate(-1)
    }

    useEffect(() => {
        const handleBackButton = stage === 'submit' ? () => {
            setStage('form');
        } : () => {
            navigate(-1);
        };
        WEB_APP_API.BackButton.offClick(handleBackButton);
        WEB_APP_API.BackButton.onClick(handleBackButton);
        WEB_APP_API.BackButton.show();

        return () => {
            WEB_APP_API.BackButton.hide();
            WEB_APP_API.BackButton.offClick(handleBackButton);
        }
    }, [stage]);

    const stageFormComponent = useMemo(() => {
        return <>
            <FlexBox offsetTop={20} direction="column" width="calc(100% - 76px)">
                <InputItem onChange={setAddress} value={address} text={t('deposit.input_address')}/>
            </FlexBox>
            <FlexBox offsetTop={20} direction="column" width="calc(100% - 76px)">
                <InputItem
                    onChange={(e) => {
                        if (/^\d*\.?\d*$/.test(e)) {
                            setSum(e);
                        }
                    }} value={sum} text={t('deposit.sum')} withIcon/>
            </FlexBox>
        </>
    }, [t, sum, address])

    const stageSubmitComponent = useMemo(() => {
        return <>
            <FlexBox offsetTop={20} direction="column" width="calc(100% - 76px)">
                <SplitLine/>
            </FlexBox>
            <FlexBox style={{marginLeft: '10px'}} align="flex-start" gy={4} offsetTop={10} direction="column" width="calc(100% - 66px)">
                <TextBlock fontSize={18} lineHeight={18} fontWeight={400} color="#FFFFFFCC">{t('deposit.your_address')}</TextBlock>
                <TextBlock fontSize={19} lineHeight={19} fontWeight={400}>{address}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={4} direction="column" width="calc(100% - 76px)">
                <SplitLine/>
            </FlexBox>
            <FlexBox style={{marginLeft: '10px'}} align="flex-start" gy={4} offsetTop={10} direction="column" width="calc(100% - 66px)">
                <TextBlock fontSize={18} lineHeight={18} fontWeight={400} color="#FFFFFFCC">{t('deposit.transfer_sum')}</TextBlock>
                <TextBlock fontSize={19} lineHeight={19} fontWeight={400}>{`${sum} TON`}</TextBlock>
            </FlexBox>
            <FlexBox offsetTop={4} direction="column" width="calc(100% - 76px)">
                <SplitLine/>
            </FlexBox>
            <FlexBox offsetTop={4} direction="column" width="calc(100% - 76px)">
                <InputItem value={TON_DEPOSIT_WALLET} text={t('deposit.send_ton_here')} type="copy"/>
            </FlexBox>
            <FlexBox offsetTop={16} direction="column" width="calc(100% - 76px)">
                <InputItem value={memoTag} text={t('deposit.memo')} type="copy"/>
            </FlexBox>
            <FlexBox offsetTop={8} direction="column" align="flex-start" width="calc(100% - 76px)">
                <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color="#ED4072"
                           style={{textAlign: "left", display: 'inline-block'}}>{t('deposit.warning_memo')}
                    <div style={{display: 'inline-block', margin: '4px 0 0 4px'}}><QuestionIcon fill="#4BA4FF" width={16} height={16}/></div>
                </TextBlock>
            </FlexBox>
        </>
    }, [t, sum, address, memoTag])

    return <DefaultPageWrapper disableNavbar disableMenu>
        <FlexBox direction="column" width="100%" align="center" justify="space-between" style={{minHeight: '100vh'}}>
            <FlexBox direction="column" width="100%" align="center">
                <FlexBox offsetTop={76} direction="column" align="flex-start" width="calc(100% - 76px)">
                    <TextBlock fontSize={28} lineHeight={28} fontWeight={700}>{t('settings.deposit')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={36} direction="column" align="flex-start" width="calc(100% - 76px)">
                    <TextBlock fontSize={15} lineHeight={18} fontWeight={700} style={{textAlign: "left"}}>{t('deposit.description')}</TextBlock>
                </FlexBox>
                <FlexBox offsetTop={14} direction="column" align="flex-start" width="calc(100% - 76px)">
                    <TextBlock fontSize={15} lineHeight={18} fontWeight={700} color="#ED4072"
                               style={{textAlign: "left"}}>{t('deposit.warning')}
                        {stage === 'form' && <div style={{display: 'inline-block', margin: '4px 0 0 4px'}}><QuestionIcon fill="#4BA4FF" width={16} height={16}/></div>}
                    </TextBlock>
                </FlexBox>
                {stage === 'form' && stageFormComponent}
                {stage === 'submit' && stageSubmitComponent}
            </FlexBox>
            <FlexBox width="calc(100% - 76px)" offsetBottom={128} offsetTop={40}>
                <ProfileButton disabled={isButtonDisabled} blue width="100%" onClick={onSubmit}>
                    <TextBlock fontSize={18} lineHeight={18} fontWeight={700}>{t('settings.deposit')}</TextBlock>
                </ProfileButton>
            </FlexBox>
        </FlexBox>
        <RequestModal isOpen={isModalOpen} closeModal={onCloseModal}/>
    </DefaultPageWrapper>
}