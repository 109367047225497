import {useAppDispatch, useAppSelector} from "../storeHooks";
import {loadReferrals, selectorReferrals} from "../../slices/referralsSlice";
import {useEffect} from "react";
import {selectorAuth} from "../../slices/authSlice";

export const useGetReferrals = () => {

    const data = useAppSelector(selectorReferrals)
    const auth = useAppSelector(selectorAuth)
    const dispatch = useAppDispatch()

    useEffect(() => {

        if (data.isLoading || data.isReady || !auth.token || data.error) return
        dispatch(loadReferrals({}))

    }, [data.isLoading, data.isReady, auth.token, data.error])

    return {
        referrals: data.referrals,
        totalTon: data.totalTon,
        totalBones: data.totalBones
    }
}