import {FlexBox} from "../../../../shared/components/FlexBox/FlexBox";
import {useTranslation} from "react-i18next";
import {FC} from "react";
import {TextBlock} from "../../../../shared/components/Text/TextBlock";
import {MiningItem} from "./MiningItem";
import {useGetFarm} from "../../../../shared/hooks/api/useGetFarm";
import {Factory, UserFactory} from "../../../../shared/slices/farmSlice";

export const MiningList: FC<{factories: (Factory & UserFactory)[]}> = ({factories}) => {

    return <FlexBox direction="column" width="100%" gy={40}>
        <FlexBox direction="column" width="100%" gy={10} align="flex-start">
            {/*<TextBlock fontSize={20} lineHeight={20} fontWeight={700}>Предприятия</TextBlock>*/}
            <FlexBox direction="column" width="100%" gy={14}>
                {factories.map((item: any, index: number) => <MiningItem key={index} item={item} />)}
            </FlexBox>
        </FlexBox>
{/*        <FlexBox direction="column" width="100%" gy={10} align="flex-start">
            <TextBlock fontSize={20} lineHeight={20} fontWeight={700}>Предприятие</TextBlock>
            <FlexBox direction="column" width="100%" gy={14}>
                {items.map((item: any, index: number) => <MiningItem key={index} item={item} />)}
            </FlexBox>
        </FlexBox>*/}
    </FlexBox>
}