import {
    ChargeToCardInput,
    ChargeToCardModalWrapper, ChargeToCardSubmit,
    ChargeToCardWalletSelector,
    ChargeToCardWalletSelectorItem
} from "./styles";
import React, {FC, useEffect, useMemo, useState} from "react";
import Modal from 'react-modal';
import {TextBlock} from "../../components/Text/TextBlock";
import {FlexBox} from "../../components/FlexBox/FlexBox";
import {HoneyIcon} from "../../icons/HoneyIcon";
import {CoinIcon} from "../../icons/CoinIcon";
import {useTranslation} from "react-i18next";
import {createDeposit} from "../../actions/api/createDeposit";
import {chooseCard} from "../../actions/api/chooseCard";
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks";
import {selectorAuth, setHoney, setTon} from "../../slices/authSlice";
import {selectorCardGame, setHasUserBet} from "../../slices/cardGameSlice";
import {TonIcon} from "../../../pages/DepositPage/styles";
import {InputItem} from "../../../pages/DepositPage/components/InputItem";

const customStyles = {
    overlay: {
      zIndex: 999999999,
      background: 'rgba(41, 38, 58, .75)',
},
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        background: 'none',
        border: 'none'
    },
};

export const ChargeToCardModal: FC<{closeModal: () => void, isOpen: boolean, activeCard: string, alreadyHasBet: boolean}> = ({closeModal, isOpen, activeCard, alreadyHasBet}) => {

    const [activeWallet, setActiveWallet] = useState<'Honey' | 'TON'>('TON')
    const [count, setCount] = useState("")
    const { t } = useTranslation()
    const { game } = useAppSelector(selectorCardGame)
    const dispatch = useAppDispatch()
    const { notTokens, bones } = useAppSelector(selectorAuth)
    const countNumber = parseFloat(count) || 0

    const isButtonDisabled = useMemo(() => {
        if (!countNumber) return true

        if (activeWallet === 'Honey') {
            return (bones - countNumber) < 0
        }
        return (notTokens - countNumber) < 0
    }, [notTokens, bones, countNumber, activeWallet])

    useEffect(() => {
        setCount("")
        setActiveWallet("TON")
    }, [isOpen])

    const onSubmit = () => {
        if (isButtonDisabled) return
        chooseCard(activeCard, game.round.id, countNumber, activeWallet === 'Honey' ? 'BONES' : 'TON').then((r) => {
            dispatch(setHasUserBet({
                hasUserBet: true,
                bettedCardId: activeCard
            }))
            if (activeWallet === 'Honey') dispatch(setHoney(bones - countNumber))
            else dispatch(setTon(notTokens - countNumber))

            closeModal()
        })
            .catch((err) => console.error(err))
    }

    return <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <ChargeToCardModalWrapper>
            <FlexBox direction="column" justify="center">
                <TextBlock fontSize={16} lineHeight={16} fontWeight={600} style={{textAlign: "center"}}>
                    {t('cards.how_much_place_1')}
                </TextBlock>
                <TextBlock fontSize={16} lineHeight={16} fontWeight={600} style={{textAlign: "center"}}>
                    {t('cards.how_much_place_2', {number: 3})}
                </TextBlock>
            </FlexBox>
            <FlexBox offsetTop={20} gx={10}>
                {alreadyHasBet ? <InputItem
                    onChange={(value) => {
                        if ((activeWallet === 'TON' ? /^\d*\.?\d*$/ : /^\d*$/).test(value)) {
                            setCount(value);
                        }
                    }} value={count} text={''} withIcon/> : <>
                    <ChargeToCardInput
                    onChange={(e) => {
                        const value = e.target.value;
                        if ((activeWallet === 'TON' ? /^\d*\.?\d*$/ : /^\d*$/).test(value)) {
                            setCount(value);
                        }
                    }} value={count} type="text"/>
                <ChargeToCardWalletSelector>
                    <ChargeToCardWalletSelectorItem onClick={() => {setActiveWallet('Honey'); setCount("")}} position="left" active={activeWallet === 'Honey'}><HoneyIcon height={34} width={38}/></ChargeToCardWalletSelectorItem>
                    <ChargeToCardWalletSelectorItem onClick={() => {setActiveWallet('TON'); setCount("")}} position="right" active={activeWallet === 'TON'}><TonIcon style={{position: 'initial'}}/></ChargeToCardWalletSelectorItem>
                </ChargeToCardWalletSelector>
                </>}
            </FlexBox>

            <FlexBox offsetTop={14} width="100%">
                <ChargeToCardSubmit disabled={isButtonDisabled} onClick={onSubmit}><TextBlock fontSize={16} lineHeight={16} fontWeight={600}>{`${t('cards.place')}: ${count} ${activeWallet}`}</TextBlock></ChargeToCardSubmit>
            </FlexBox>
        </ChargeToCardModalWrapper>
    </Modal>
}